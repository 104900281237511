/* GENERIC STUFF TO BE REUSE BY OTHER COMPONENTS */
.Border {
    border-width: 2px;
    border-style: solid;
    border-color: var(--gray-lighter);
}

.Headline {
    margin: 1em 0;
    text-align: center;
    font-weight: 700;

    & p {
        margin: 0.75em 0;
    }
}

.SubHeader {
    padding: 0.75em 0.75em;
    font-size: 1em;
    font-weight: normal;
    margin: 0;
}

.Section {
    margin: 0.5em 0;
}

.SectionHeader {
    composes: SubHeader;

    font-size: 1.1em;
}

.SubSection {
    color: var(--gray-darker);
}

.SubSectionHeader {
    composes: SubHeader;
}

.SubSection__Content {
    color: var(--gray);
    padding: 1em;
}

.Summary {
    & h3 {
        color: var(--color-primary);
        margin-bottom: 0.125em;
    }

    & p,
    & ul {
        margin: 0.125em 0 0.5em;
    }

    & li > p {
        margin: 0;
    }

    & h3 > dl {
        margin: 0;
        display: inline-flex;

        & dt::after {
            content: ': ';
            white-space: pre;
        }

        & dd {
            margin: 0;
            font-style: italic;
        }
    }
}

.Footer {
    & dl {
        display: flex;
    }
    & dd {
        margin: 0 0 0 0.25em;
        font-weight: 700;
    }
}
